.contact-wrapper {

    .title {
        font-size: 3.8rem;
        margin-bottom: 0;
    }

    .email-form {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin: 0 auto;

        .label {
            margin-top: 1.5rem;
            text-align: left;
            font-weight: 600;
            font-size: 1.2rem;
            // margin-bottom: -1rem;
        }

        .input {
            width: 100%;
            border: none;
            border-radius: 5px;
            height: 40px;
            border: 1px solid #343d46;
            padding-left: 0.5rem;
        }

        .message {
            height: 125px;
            width: 100%;
        }

        .response-container {
            display: flex;
            margin-top: 3rem;
            justify-content: space-between;
            
            .btn-wrapper {
                height: 8rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 45%;

                button {
                    width: 100%;
                    border-radius: 5px;
                    border: none;
                    background: #016EB0;
                    color: #ffffff;
                    font-size: 1.2rem;
                    font-weight: 600;
                    padding: 1rem 7rem;
                }
        

            }

            p {
                position: relative;
                top: .5rem;
                font-weight: 600;
                text-align: left;
                font-size: 1.8rem;
                margin: 0 2rem;
                width: 10%;
                text-align: center;
            }

            .link-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 45%;
                
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: #343d46;
                    font-size: 1.6rem;
    
                    img {
                        width: 30px;
                        margin-right: 5px;
                    }
                }
            }


        }
    }
}