.home-wrapper {
    // background: #C0C0C0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 85vh;

    .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: baseline;
        margin: 0 auto;

        .name {
            font-size: 3.8rem;
            margin-bottom: 0;
            margin-top: 0;
        }

        .title {
            font-size: 2.8rem;
            margin-top: 0;
            color: #C0C0C0
        }

        .contact-icons {

            .icons {
                width: 50px;
                height: 50px;
                margin: 0 1rem;

                &:hover {
                    color: white;
                }
            }

        }

        .next-page-nav {
            margin: 0 auto;
            max-width: 1024px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 6rem;

            .bottom-nav {
                text-decoration: none;

                &:hover {

                    p {

                        .next {
                            color: #343d46;
                            transition: color 0.25s ease-in-out;
                        }
                    }
                }

                p {
                    color: #C0C0C0;
                    display: flex;
                    align-items: center;
                    font-weight: 900;

                    .next {
                        width: 20px;
                        font-size: 3rem;
                        font-weight: 400;
                        padding-left: .4rem;

                        &:hover {
                            color: #343d46
                        }
                    }
                }
            }
        }
    }

    .navlink {
        text-decoration: none;

        // &:active {
        //     color: #343d46
        // }

        .contact {
            margin-top: 3rem;
            border-radius: 5px;
            border: none;
            background: #016EB0;
            color: #ffffff;
            font-size: 1.2rem;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: baseline;
            padding: 1rem 7rem;
    
            img {
                width: 10px;
                margin-left: .5rem;
            }
        }
    }


}