.project {
    // border: 1px solid red;
    text-align: center;
    width: 20rem;
    height: 100%;
    padding: 2rem;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 3rem;
    text-align: left;
    margin: 2rem;

    .img-source {
        width: 100%;
    }

    .name {
        font-size: 2.2rem;
    }

    .skills {
        font-size: 1.4rem;
    }

    .description {
        font-size: 1rem;
    }

    .links-container {
        margin: 0;

        .icon-links {
            text-decoration: none;
            color: #C0C0C0;
            display: flex;

            &:hover {
                color: #343d46;
                transition: color 0.25s ease-in-out;
            }

            .icons {
                width: 20px;
                margin-right: .5rem;
            }

            p {

            }
        }
    }
}