.skills-wrapper {
    margin: 0 auto;

    .title {
        font-size: 3.8rem;
        margin-bottom: 0;
        margin-top: 0;
    }

    .skills-container {
        display: flex;
        justify-content: center;
        margin: 0 auto 3rem auto;

        .icon {
            width: 150px;
            height: 150px;
            margin: 0 3rem;
        }
    }

    h3 {
        margin-top: 6rem;
        font-size: 1.8rem;

        a {

            u {
                color: #C0C0C0;

                &:hover {
                    color: #343d46;
                    transition: color 0.25s ease-in-out;
                }
            }
        }
    }
}