.App {
  text-align: center;

  


  .navigation {
    position: relative;
    width: 100px;
    left: 450px;
    bottom: 200px;
    color: red;
    // background: red;
    // right: 200px;
  }

  .skills-container {
    // display: flex;
    margin: 3rem auto;

    svg {
      width: 150px;
      margin-left: 3rem;
      margin-right: 3rem;
    }
  }

  .bottom-divider {
    margin: 3rem auto;
    width: 12%;
    border: 2px solid #C0C0C0;
  }
}

body {
  margin: 0 auto;
  background: #f5f5f5;
  color: #343d46;
  font-family: 'Roboto', sans-serif;
}
