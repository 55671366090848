.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    background: #f5f5f5;
    height: 100px;
    max-width: 1024px;

    .home-link {
        text-decoration: none;
        
        .logo {
            margin: 0 2rem;
            font-family: 'Roboto', sans-serif;
            color: #343d46;
            font-weight: 900;
            font-size: 2.2rem;
            line-height: 30px;
    
            span {
                color: #C0C0C0;
            }
        }
    }



    .navlinks {

        .navlink {
            font-size: 1.4rem;
            font-family: 'Roboto', sans-serif;
            color: #C0C0C0;
            font-weight: 500;
            text-decoration: none;
            margin: 2rem 1.5rem;

            &:active {
                color: #343d46;
            }

            &:hover {
                color: #343d46;
                transition: color 0.25s ease-in-out;
                // color: #343d46;
            }
        }

        .active-nav {
            color: #343d46;
          }
    }

    .contact {
        font-size: 1.8rem;
        font-family: 'Roboto', sans-serif;
        color: #000000;
        font-weight: 500;
        text-decoration: none;
        margin: 2rem;
    }
}