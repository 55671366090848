.about-wrapper {


    .title {
        font-size: 3.8rem;
        margin-bottom: 0;
        margin-top: 0;
    }

    .content-container {
        background: #ffffff;
        border-radius: 5px;
        margin: 3rem auto;
        padding: 3rem;
        display: flex;
        width: 50rem;

        img {
            width: 40%;
            border-radius: 5px;
        }

        p {
            margin-top: 0;
            width: 100%;
            text-align: left;
            font-size: 1.2rem;
            margin-right: 1rem;
        }

    }

    h3 {
        margin: 3rem 0;
        font-size: 1.8rem;

        a {

            u {
                color: #C0C0C0;

                &:hover {
                    color: #343d46;
                    transition: color 0.25s ease-in-out;
                }
            }
        }
    }
}