.projects-wrapper {

    .info-container {

        .title {
            font-size: 3.8rem;
            margin-bottom: 0;
            margin-top: 0;
        }

        .project-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    h3 {
        font-size: 1.8rem;

        a {

            u {
                color: #C0C0C0;

                &:hover {
                    color: #343d46;
                    transition: color 0.25s ease-in-out;
                }
            }
        }
    }
}